


























import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class extends Vue {
  get entryLink(): string {
    return process.env.VUE_APP_ENTRY_URL;
  }
}
