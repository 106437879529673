var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navbar'),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"m-auto content-title-wrapper"},[_c('h4',{staticClass:"content-title content-small-text m-0 p-0"},[_vm._v(" You are benched against the median and average values of your peers. Usually, average values are more ambitious as they are prone to upward-outliers, especially in the long-run, due to the survival bias. ")]),_c('p',{staticClass:"content-small-text"},[_c('small',[_vm._v(" This statistics page will expire at "+_vm._s(_vm.expires.toLocaleString("en-US"))+". ")])])])]),_c('div',{staticClass:"analyze d-flex justify-content-center"},[_c('div',{staticClass:"analyze-wrapper transform-x"},[_c('div',{staticClass:"analyze-content transform-x"},[_c('div',{staticClass:"analyze-content"},[_c('el-table',{attrs:{"loading":_vm.statistics.headers.length === 0,"data":_vm.statistics.headers,"empty-text":"No data","width":"1300px"}},[_c('el-table-column',{attrs:{"width":"270px","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"first-table-box"},[_c('div',{staticClass:"table-header table-border-middle table-header-first table-border-left d-flex flex-column justify-content-center"},[_c('h4',{staticClass:"m-0",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(statistic.name)+" ")])])])]}}])}),_c('el-table-column',{attrs:{"width":"120px","prop":"input"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"first-table-box"},[_c('div',{staticClass:"table-header table-border-middle d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title header-black"},[_vm._v(" "+_vm._s(statistic.stage)+" ")])])])]}}])}),_c('el-table-column',{attrs:{"prop":"current"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"first-table-box"},[_c('div',{staticClass:"table-header table-border-middle d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title"},[_vm._v(" "+_vm._s(statistic.firstColumn.name)+" ")])]),_c('div',{staticClass:"item-wrapper d-flex justify-content-between"},[_c('div',{staticClass:"item-wrapper__item d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(statistic.firstColumn.type)+" ")])]),_c('div',{staticClass:"item-wrapper__item d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(statistic.firstColumn.value)+" ")])])])])]}}])}),_c('el-table-column',{attrs:{"prop":"year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"first-table-box"},[_c('div',{staticClass:"table-header table-border-middle d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title"},[_vm._v(" "+_vm._s(statistic.secondColumn.name)+" ")])]),_c('div',{staticClass:"item-wrapper d-flex justify-content-between"},[_c('div',{staticClass:"item-wrapper__item d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(statistic.secondColumn.type)+" ")])]),_c('div',{staticClass:"item-wrapper__item d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(statistic.secondColumn.value)+" ")])])])])]}}])}),_c('el-table-column',{attrs:{"prop":"nextStage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"first-table-box"},[_c('div',{staticClass:"table-header table-border-middle table-border-right d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title"},[_vm._v(" "+_vm._s(statistic.thirdColumn.name)+" ")])]),_c('div',{staticClass:"item-wrapper flex-grow-1"},[_c('div',{staticClass:"item-wrapper__item item-wrapper__item-full d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(statistic.thirdColumn.type)+" ")])])])])]}}])})],1)],1),_c('div',{staticClass:"analyze-second analyze-content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.statistics.stats.length === 0),expression:"statistics.stats.length === 0"}],attrs:{"data":_vm.statistics.stats,"empty-text":"No data","width":"1300px"}},[_c('el-table-column',{attrs:{"width":"270px","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-header table-border-middle table-header-first table-border-left d-flex flex-column justify-content-center"},[_c('h4',{staticClass:"m-0",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(statistic.name)+" ")]),_c('h6',{staticClass:"m-0 type"},[_vm._v(" "+_vm._s(_vm.metricType[statistic.inputType])+" ")])]),_c('ul',{staticClass:"item-wrapper"},_vm._l((Object.keys(statistic.metrics)),function(k,i){return _c('li',{key:k,staticClass:"item-criteria d-flex align-items-center",class:i === 2 ? 'item-text-bold' : ''},[_vm._v(" "+_vm._s(_vm.testTypes[k])+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"width":"120px","prop":"input"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-header table-border-middle d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title header-black"},[_vm._v(" "+_vm._s(_vm.nf(statistic.input, statistic.inputType, false))+" ")])]),_c('ul',{staticClass:"item-wrapper"},_vm._l((_vm.takeMetrics(statistic)),function(item){return _c('li',{key:item,staticClass:"d-flex justify-content-center align-items-center metric-item"},[_vm._v(" "+_vm._s(_vm.metricType[item])+" ")])}),0)])]}}])}),_c('el-table-column',{attrs:{"prop":"current"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var statistic = ref.row;
return [_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-header table-border-middle d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title"},[_vm._v(" Others at your current life-cycle stage ")])]),_c('ul',{staticClass:"item-wrapper"},[_c('li',{staticClass:"d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'benchmark',
                            'current'
                          )),function(stat,key){return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(key === 0 ? "Median" : "Average")+" ")]),_c('h6',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(_vm.nf(stat.amount, statistic.inputType))+" ")])])}),0),_c('li',{staticClass:"d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'delta',
                            'current'
                          )),function(ref,key){
                          var percent = ref.percent;
                          var pptsPercent = ref.pptsPercent;
return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item-card",class:_vm.stateClass(percent, statistic)},[_c('p',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(_vm.nf( pptsPercent || percent, statistic.inputType === 1 ? 3 : 1 ))+" ")])])}),0),_c('li',{staticClass:"d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'performance',
                            'current'
                          )),function(ref,key){
                          var state = ref.state;
return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item-card",class:_vm.stateClass(state, statistic)},[_c('p',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(state)+" ")])])}),0)])])]}}])}),_c('el-table-column',{attrs:{"prop":"year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var statistic = ref.row;
return [_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-header table-border-middle d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title"},[_vm._v(" Others in 12 months from your current stage ")])]),_c('ul',{staticClass:"item-wrapper"},[_c('li',{staticClass:"d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'benchmark',
                            'yearLater'
                          )),function(stat,key){return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(key === 0 ? "Median" : "Average")+" ")]),_c('h6',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(_vm.nf(stat.amount, statistic.inputType))+" ")])])}),0),_c('li',{staticClass:"item d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'delta',
                            'yearLater'
                          )),function(ref,key){
                          var percent = ref.percent;
                          var pptsPercent = ref.pptsPercent;
return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item-card item",class:_vm.stateClass(percent, statistic)},[_c('p',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(_vm.nf( pptsPercent || percent, statistic.inputType === 1 ? 3 : 1 ))+" ")])])}),0)])])]}}])}),_c('el-table-column',{attrs:{"prop":"nextStage"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var statistic = ref.row;
return [_c('div',{staticClass:"table-box"},[_c('div',{staticClass:"table-header table-border-middle table-border-right d-flex align-items-center justify-content-center"},[_c('h4',{staticClass:"m-0 header-title"},[_vm._v(" Others at the "+_vm._s(_vm.survey.nextFundingStage)+" funding round ")])]),_c('ul',{staticClass:"item-wrapper"},[_c('li',{staticClass:"item d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'benchmark',
                            'nextStage'
                          )),function(stat,key){return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item-card"},[_c('p',{staticClass:"m-0 text-center item-text"},[_vm._v(" "+_vm._s(key === 0 ? "Median" : "Average")+" ")]),_c('h6',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(_vm.nf(stat.amount, statistic.inputType))+" ")])])}),0),_c('li',{staticClass:"item d-flex justify-content-between"},_vm._l((_vm.takeFromStatistics(
                            statistic,
                            'delta',
                            'nextStage'
                          )),function(ref,key){
                          var percent = ref.percent;
                          var pptsPercent = ref.pptsPercent;
return _c('div',{key:key,staticClass:"d-flex flex-column align-content-center justify-content-center item item-card",class:_vm.stateClass(percent, statistic)},[_c('p',{staticClass:"m-0 text-center item-text-bold"},[_vm._v(" "+_vm._s(_vm.nf( pptsPercent || percent, statistic.inputType === 1 ? 3 : 1 ))+" ")])])}),0)])])]}}])})],1)],1)])])]),_vm._m(0),_c('transparency')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mb-4 pb-4"},[_c('p',[_c('small',[_vm._v(" * Classified as "),_c('strong',[_vm._v("on track")]),_vm._v(" if user values range within +/- 5% of the benchmark. Classified as "),_c('strong',[_vm._v("ahead")]),_vm._v(" if user values are more than 5% above the benchmark. Else classifed "),_c('strong',[_vm._v("behind")]),_vm._v(". ")])]),_c('p',[_c('small',[_vm._v(" ** Classified as being "),_c('strong',[_vm._v("sufficient")]),_vm._v(", if the cash run rate is larger than six months or the cash flow is positive. However, this rule of thumb does not replace sophisticated and essential liquidity planning for your individual venture. ")])])])}]

export { render, staticRenderFns }