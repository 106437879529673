<script>
import { Doughnut } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: Doughnut,
  components: {
    ChartJsPluginDataLabels
  },
  props: ["chartdata"],
  data: () => ({
    options: {
      legend: {
        //display: false,
        //align: "start"
        fontFamily: "Poppins"
      },
      cutoutPercentage: 70,
      plugins: {
        datalabels: {
          color: "#fff",
          font: {
            family: "Poppins"
          }
        }
      }
    }
  }),
  mounted() {
    this.renderChart(this.chartdata, this.options);
  }
};
</script>
