



















































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapActions, mapState } from "vuex";
import { Answerable, BenchmarkSurveyAnswers } from "@/interfaces/answers";
import {
  computeStats,
  StageState,
  Period,
  Statistics,
  MetricType,
  StatisticsObject
} from "@/interfaces/benchmark";
import Navbar from "@/components/Navbar.vue";
import { EvaluationType } from "@/data";
import Transparency from "@/components/Transparency.vue";

@Component({
  components: { Navbar, Transparency },
  computed: {
    ...mapState(["answers"])
  },
  methods: {
    ...mapActions(["fetchAnswers"])
  }
})
export default class Analyze extends Vue {
  answers!: Answerable;
  statistics: StatisticsObject = { headers: [], stats: [] };
  fetchAnswers!: (payload: { path: string }) => Promise<void>;
  survey: BenchmarkSurveyAnswers = new BenchmarkSurveyAnswers();
  expires?: Date;

  async created() {
    if (!this.dataUrl) {
      await this.$router.replace({ name: "NotFound" });
      return;
    }
    this.expires = new Date(
      parseInt(
        new URLSearchParams(
          this.dataUrl.substring(this.dataUrl.indexOf("?"))
        ).get("expires") as string
      ) * 1000
    );
    const allowPlaceholder = true;
    try {
      console.log(this.dataUrl);
      // try real data
      try {
        await this.fetchAnswers({ path: this.dataUrl });
        this.survey = new BenchmarkSurveyAnswers(this.answers);
      } catch (error) {
        console.error("could not fetch answers:", error.message);
        // fill placeholder if allowed
        if (allowPlaceholder && process.env.NODE_ENV === "development") {
          console.log("using placeholder values");
          this.survey = new BenchmarkSurveyAnswers();
        } else {
          if (!isNaN(this.expires.getTime()))
            throw `Could not fetch your answers, because the statistics page expired at ${this.expires.toLocaleString(
              "en-US"
            )}. You can fill out the survey again to view the benchmarks.`;
          throw `Could not fetch your answers. You can fill out the survey again to view the benchmarks.`;
        }
      }

      this.postValidate(this.survey); // throws string if error

      console.log(this.survey);
      this.statistics = computeStats(this.survey);
      console.log(
        this.statistics.stats.length,
        "benchmarks computed",
        this.statistics
      );
    } catch (error) {
      console.log(error);
      try {
        await this.$alert(error, "Error", {
          confirmButtonText: "OK"
        });
      } catch (error) {
        console.log("clicked cancel");
      }
      this.$router.push({ name: "NotFound" });
    }
  }

  postValidate(survey: BenchmarkSurveyAnswers) {
    if (survey.businessStartDate.getTime() > survey.currentKPIMonth.getTime())
      throw `Business start date (${survey.businessStartDate.toLocaleDateString(
        "en-US",
        { month: "long", year: "numeric" }
      )}) must be before the current KPI month (${survey.currentKPIMonth.toLocaleDateString(
        "en-US",
        { month: "long", year: "numeric" }
      )})! Please fill out the survey again with valid dates.`;
  }

  nf(number: number, metricType: MetricType, roundBM = true): string {
    if (typeof number !== "number") return number;
    const roundTo = (n: number, d: number) =>
      Math.round(n / Math.pow(10, d)) * Math.pow(10, d);
    const options: any = {
      maximumFractionDigits: 0
    };
    let append = "";
    if (metricType === MetricType.PERCENT || metricType === MetricType.PPTS) {
      const ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) {
        //safari
        append = "%";
      } else {
        //chrome
        options.style = "unit";
        options.unit = "percent";
      }
      options.minimumFractionDigits = 1;
      options.maximumFractionDigits = 1;
    }
    if (metricType === MetricType.CURRENCY && roundBM) {
      const abs = Math.abs(number);
      if (abs > 1e6) number = roundTo(number, 5);
      else if (abs > 1e5) number = roundTo(number, 4);
      else number = roundTo(number, 3);
    }
    return new Intl.NumberFormat([], options).format(number) + append;
  }

  testTypes = {
    benchmark: "Benchmark",
    delta: "Delta",
    performance: "Implied relative performance*"
  };

  metricType = ["in EUR", "in %", "#", "in ppts", ""];

  takeMetrics(statistic: Statistics) {
    return Object.values(statistic.metrics).map(i => i.metricType);
  }

  takeFromStatistics(
    statistic: Statistics,
    fieldOne: "benchmark" | "delta" | "performance",
    fieldTwo?: Period
  ) {
    if (fieldTwo) {
      return Object.values(statistic.metrics[fieldOne][fieldTwo]);
    }
    return Object.values(statistic.metrics[fieldOne]);
  }

  stateClass(state: number | StageState, stat: Statistics) {
    if (stat.name === EvaluationType.CASH_BURN) return "";
    if (typeof state === "number") {
      if (stat.inputType === MetricType.PERCENT && stat.input < 0)
        return "item-wrapper__failure";
      if (state < -5) return "item-wrapper__success";
      if (state > 5) return "item-wrapper__failure";
      return "";
    }
    const st = state as StageState;
    switch (st) {
      case StageState.AHEAD:
        return "item-wrapper__success";
      case StageState.BEHIND:
        return "item-wrapper__failure";
      default:
        return "";
    }
  }

  get dataUrl() {
    // aHR0cHM6Ly9leGFtcGxlLm9yZy9oZWxsbw==
    try {
      return decodeURIComponent(atob(this.$route.params.encodedUrl));
    } catch (error) {
      return null;
    }
  }

  get surveyLink() {
    if (!this.dataUrl) return null;
    const pathArray = this.dataUrl.split("/");
    const protocol = pathArray[0];
    const host = pathArray[2];
    return protocol + "//" + host;
  }
}
