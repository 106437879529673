





















import { Component, Vue } from "vue-property-decorator";
import PieChart from "@/components/PieChart.vue";
import {
  CustomerFocus,
  FundingStage,
  Industry,
  RevenueModel
} from "@/interfaces/answers";
import Navbar from "./Navbar.vue";

@Component({ name: "Transparency", components: { PieChart, Navbar } })
export default class extends Vue {
  private colors = [
    "#0d4823",
    "#147338",
    "#188943", // primary
    "#20b458"
  ];
  private chartData = [
    {
      title: "Data distribution by industry",
      unit: "in %",
      datasets: [
        {
          data: [40, 40, 13, 7],
          backgroundColor: this.colors
        }
      ],
      labels: [Industry.OTHER, Industry.IT, Industry.FIPT, Industry.DH]
    },
    {
      title: "Data distribution by funding series type",
      unit: "in #",
      datasets: [
        {
          data: [44, 67, 31, 44],
          backgroundColor: this.colors
        }
      ],
      labels: [FundingStage.SEED, FundingStage.SA, FundingStage.SB, "Others"]
    },
    {
      title: "Data distribution by geography",
      unit: "in %",
      datasets: [
        {
          data: [86, 13, 1],
          backgroundColor: this.colors
        }
      ],
      labels: ["Western Europe *", "Americas", "APAC"]
    },
    {
      title: "Data distribution by revenue model",
      unit: "in %",
      datasets: [
        {
          data: [64, 11, 35],
          backgroundColor: this.colors
        }
      ],
      labels: [RevenueModel.TRM, RevenueModel.RRM, RevenueModel.MRM]
    },
    {
      title: "eCommerce and marketplaces vs. others",
      unit: "in %",
      datasets: [
        {
          data: [31, 69],
          backgroundColor: this.colors
        }
      ],
      labels: ["eCommerce or Marketplaces", "Other Business Model"]
    },
    {
      title: "Data distribution by customer focus ",
      unit: "in %",
      datasets: [
        {
          data: [53, 42, 5],
          backgroundColor: this.colors
        }
      ],
      labels: [CustomerFocus.B2B, CustomerFocus.B2C, CustomerFocus.MIXED]
    }
  ];
}
